
html {
  font-size: 14px;
}
body {
  line-height: 1.5;
  color: black;
  margin:0;
} 

.header{
  border: 2px solid #eee;
  display: flex;
  background-color: steelblue;
}
.image-container{ 
  margin-left: 150vh;
  margin-top: 4.5vh;
  
}
.image-container img{
  width: 40px;  
}

h1{
  margin-left: 3vh;
  font-size: xx-large;
  
}
.logo img{
  width: 65px;
  margin-top: 4vh;
  margin-left: 3vh;
  border-radius: 50vh;
}
.post-container{
  width: 500px;
  text-align: center;
  border: 2px solid #eee;
  margin-left: 75vh;
  margin-top: 5vh;
}
.top-portion{
  line-height: 1vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-location-wrapper{
  margin-left: 2vh;
  text-align: justify;
}

.name{
  font-weight: 700;
}
.location{
  color: gray;
}

.three-dots{
  margin-top: 0vh;
  margin-right: 2vh;
}

.post-image img{
  width: 500px;
  height: 450px;
}
.bottom-portion p{
  font-size: large;
  margin-top: 0;
  margin-left: 2vh;
}

.share-button img{
  width: 20px;
  margin-top: 3px;
  margin-left: 2vh;
}

.bottom-portion{
  display: flex;
}

.date-container p{
  margin-left: 52vh;
  color: gray;
  font-weight: 600;
  font-size: medium;
}
.description-likes{
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.likes-container p{
  color: gray;
  margin-top: -1vh;
  margin-left: 2vh
}

.desc-container{
  font-size: large;
  margin: 0 2vh;
  font-weight: 700;
  padding-bottom: 16px;
  margin-top: -2.5vh;
}

.wwe-logo{
  width: 800px;
}